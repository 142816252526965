<template>
	<div class="mainBox">
		<div class="feedback" @click="show=true">反馈</div>
		<img src="https://s.istero.com/admin/upload/images/20241130/a8a7ac23753c72818584fa370f07f135.jpg" style="width: 100%;height: auto;" />
		<!-- <img :src="banner" style="width: 100%;height: auto;" /> -->
<!-- 				<van-notice-bar left-icon="volume-o" scrollable text="尊敬的客户，因系统维护，暂不支持查看物流状态，但我们会保证在48小时内如期发货(节假日可能会有延迟)。给您带来的不便，敬请谅解。如有疑问，请联系客服或在线反馈。" background="#000000"
			color="#bfb88e" /> -->
		<div class="formBox">
			<van-form>
				<div class="title">兑换家油卡</div>
				<van-cell-group inset>
					<van-field v-model="card" name="card" label="卡号" placeholder="请输入卡号" type="digit" clearable
						left-icon="vip-card-o" label-width="70px" />
					<van-field v-model="password"sss type="password" name="password" label="密码" placeholder="请输入密码"
						clearable left-icon="diamond-o" label-width="70px" />
				</van-cell-group>
				<div style="margin: 16px;">
					<van-button round block type="primary" native-type="submit" color="#000000" @click="login"
						:loading="loading">
						点击兑换
					</van-button>
				</div>
			</van-form>
		</div>ss
		<div class="formBox">
			<van-form>
				<div class="title">查询物流</div>
				<van-cell-group inset>
					<van-field v-model="tel" name="tel" label="收件电话" placeholder="请输入收件电话或备用电话" type="digit" clearable
						left-icon="phone-o" label-width="85px" />
				</van-cell-group>
				<div style="margin: 16px;">
					<van-button round block type="primary" native-type="submit" color="#000000" @click="order"
						:loading="loading2">
						点击查询
					</van-button>
				</div>
			</van-form>
		</div>

		<div class="footer">&copy;Sanmark<div class="istero" @click="toistero">起零公司提供技术支持</div>
		</div>

		<van-action-sheet v-model:show="show" title="反馈">
			<div class="content">
				<van-field v-model="name" label="您的姓名" placeholder="如何称呼您(非必填)" maxlength="5" />
				<van-field v-model="phone" label="联系电话" placeholder="请留下联系电话(非必填)" type="number" maxlength="11" />
				<van-field v-model="message" rows="5" label="" type="textarea" placeholder="您有任何想法/建议可在此处进行反馈(100字以内)"
					maxlength="100" />
				<div class="btnBox">
					<van-button block color="#000000" @click="handelFeedBack" round>提交反馈</van-button>
				</div>
				<div style="font-size: 14px;padding: 10px 0px 30px 0px;">客服电话：<a href="tel:4007778479"
						style="color:#000000">400-7778479</a></div>
			</div>
		</van-action-sheet>

	</div>
</template>
<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {
		showToast,
		showNotify,
		NoticeBar
	} from 'vant';

	import {
		verifyCard,
		verifyOrder,
		getBanner,
		feedBack
	} from '@/api/home';
	export default {
		name: 'App',
		data() {
			return {
				card: "",
				password: "",
				name: "",
				phone: "",
				message: "",
				show: false,
				username: "",
				tel: "",
				banner: "",
				loading: false,
				loading2: false,
			}
		},
		created() {
			let that = this
			that.getBanner()
		},
		setup() {
			const router = useRouter()
			const route = useRoute()

			pushWithQuery()

			function pushWithQuery() {
				// if (localStorage.getItem("sanmark_access_token")) {
				// 	router.push({
				// 		path: "/change"
				// 	})
				// }

			}
		},
		methods: {
			toistero() {
				window.location = 'https://www.istero.com'
			},
			getBanner() {
				let that = this
				getBanner({}).then(res => {
					if (res.code == 200) {
						that.banner = res.data.zb_img
					}
				})
			},
			handelFeedBack() {
				let that = this
				if (!that.message) {
					showNotify("请输入反馈内容")
				} else {
					feedBack({
						name: that.name,
						phone: that.phone,
						message: that.message
					}).then(res => {
						if (res.code == 200) {
							that.message = ""
							that.show = false
							that.name = ""
							that.phone = ""
							showNotify({
								message: "感谢您的反馈，我们将尽快查看",
								type: "success"
							})
						}
					})

				}
			},
			login() {
				let that = this
				if (!that.card) {
					showNotify("卡号不能为空")
					return false
				} else if (!that.password) {
					showNotify('密码不能为空')
					return false
				} else {
					that.loading = true
					verifyCard({
						card: that.card,
						password: that.password
					}).then(res => {
						that.loading = false
						if (res.code == 200) {
							localStorage.clear();
							localStorage.setItem("sanmark_access_token", "");
							localStorage.setItem("sanmark_access_token", res.data);
							showNotify({
								message: '验证成功',
								type: 'success',
								duration: 1000
							});
							that.loading = false
							that.$router.push({
								path: "/change"
							})
						}
					})
				}
			},
			order() {
				let that = this
				if (!that.tel) {
					showNotify('收件号码不能为空')
					return false
				} else {
					that.loading2 = true
					verifyOrder({
						// username: that.username,
						tel: that.tel
					}).then(res => {
						that.loading2 = false
						if (res.code == 200) {
							showNotify({
								message: '已找到订单',
								type: 'success',
								duration: 1000
							});
							that.$router.push({
								path: "/order",
								query: {
									username: that.username,
									tel: that.tel
								}
							})
						}
					})
				}
			}
		}


	}
</script>
<style>
	body {
		background: rgb(24, 24, 24);
	}

	.mainBox {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}



	.mainBox .formBox {
		width: 85%;
		padding: 5px;
		text-align: center;
		background: #ffffff;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		border-radius: 10px;

	}

	.mainBox .feedback {
		position: absolute;
		right: 0px;
		top: 10%;
		background: #a98146;
		padding: 5px 10px;
		font-size: 12px;
		border-radius: 10px 0px 0px 10px;
	}

	.mainBox .title {
		font-size: 14px;
		padding: 15px 0px;
	}

	.mainBox .footer {
		font-size: 12px;
		padding: 30px 0px;
		color: #676767;
		text-align: center;
	}

	.mainBox .footer .istero {
		font-size: 11px;
		border-bottom: #4c4c4c solid 1px;
		margin-top: 5px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}

	.mainBox .btnBox {
		width: 95%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}
</style>